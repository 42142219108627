<template>
    <div class="fixed h-20 inset-x-0 bottom-0 bg-gray-400 flex items-center justify-center">
        <select @input="handleChange">
            <option>azure</option>
            <option>noble</option>
            <option>lagos</option>
            <option>tinto</option>
            <option>nouveau</option>
            <option>conservatory</option>
            <option>plum-harvest</option>
            <option>cream-soda</option>
            <option>spring</option>
            <option>luxe</option>
        </select>
    </div>
</template>
<script setup>
const handleChange = event => {
    const theme = event.target.value;

    document.documentElement.setAttribute('data-theme', theme);
};
</script>
