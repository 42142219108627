import './bootstrap';
import '../css/app.css';
import {createApp} from "vue";

const components = import.meta.glob('./**/*.vue', { eager: true })

const app = createApp({});

Object.entries(components).forEach(([path, definition]) => {
    const componentName = path.split('/').pop().replace(/\.\w+$/, '')
    app.component(componentName, definition.default)
});

app.mount('#app');
