<template>
    <nav class="z-20 bg-white h-14 shadow-md fixed inset-x-0 top-0 flex items-center justify-between px-20">
        <div class="flex items-center gap-8 min-w-0">
            <button id="hamburger" class="flex-shrink" @click="handleClick" :class="{'open': open}">
                <span></span>
                <span></span>
                <span></span>
            </button>
            <div class="flex-1 truncate">
                <a href="/" class="text-xl primary-font primary-color">
                    <slot name="title"></slot>
                </a>
            </div>
        </div>
        <div class="hidden sm:flex flex-shrink-0" type="contact-us">
            <slot name="action"></slot>
        </div>
    </nav>

    <template v-if="open">
        <div id="menu" class="z-10 bg-white fixed top-14 bottom-0 left-0 w-[500px] max-w-full shadow-md">
            <nav @click="handleLinkClick" class="flex flex-col gap-8 pl-[135px] pt-8 text-xl primary-color">
                <slot name="links"></slot>
            </nav>
        </div>
    </template>
</template>
<style scoped>
#hamburger {
    width: 20px;
    height: 15px;
    position: relative;
    margin: 20px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#hamburger span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--primary-color);
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#hamburger span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#hamburger span:nth-child(2) {
    top: 6px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#hamburger span:nth-child(3) {
    top: 12px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#hamburger.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -1px;
    left: 0;
}

#hamburger.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

#hamburger.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 13px;
    left: 0;
}
</style>
<script setup lang="ts">
import {ref} from "vue";

const open = ref(false);
const handleClick = () => open.value = !open.value;
const handleLinkClick = (event: PointerEvent) => {
    if (event.target instanceof HTMLAnchorElement) {
        open.value = false;
    }
}
</script>
