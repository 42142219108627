<template>
    <div class="mb-14">
        <a class="w-full" :href="address.get_directions_button_action" target="_blank">
            <div
                class="w-full pt-[50%] bg-center bg-no-repeat"
                :style="{ 'background-image': `url('${props.feature.map_src}')`}"
            ></div>
        </a>
    </div>
</template>
<script setup lang="ts">
import {computed, Prop} from "vue";
import {CompiledMapFeature, FooterAddress} from "@/components/types.js";

const props = defineProps({
    feature: {
        type: Object as Prop<CompiledMapFeature>,
    },
    address: {
        type: Object as Prop<FooterAddress>,
    }
});
</script>
