<template>
    <article>
        <template v-if="post.image_url">
            <a :href="post.google_search_url || '#'">
                <img class="mb-8" :src="post.image_url" alt="Image related to the business' update post" />
            </a>
        </template>

        <a :href="post.google_search_url || '#'" class="hover:brightness-75 primary-color">
            Posted on {{ post.posted_on }}
        </a>

        <template v-if="post.description">
            <p>{{ truncate(post.description) }}</p>
        </template>

        <template v-if="post.button_text && post.button_action">
            <div class="mt-4">
                <secondary-link :href="post.button_action">{{ post.button_text }}</secondary-link>
            </div>
        </template>
    </article>
</template>
<script setup lang="ts">
import {PropType} from "vue";
import {CompiledPost} from "@/components/types";
import SecondaryLink from "@/components/shared/secondary-link.vue";

defineProps({
    post: {
        type: Object as PropType<CompiledPost>,
        required: true,
    }
});

const truncate = (string: string) => {
    // if the string is only slightly longer than
    // the cutoff, just show the whole thing
    if (string.length < 170) {
        return string;
    }

    return string.substring(0, 150) + "...";
}
</script>

