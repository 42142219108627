<template>
    <a :key="icon" href="#" class="flex items-center gap-2 primary-color">
        <i :class="icon"></i>
        <slot></slot>
    </a>
</template>
<script setup>
import {computed} from "vue";

const props = defineProps({
    type: {
        type: String,
        required: true,
    }
});

const actions = [
    {value: 'contact-us', icon: 'fa-solid fa-envelope'},
    {value: 'call-now', icon: 'fa-solid fa-phone'},
    {value: 'get-directions', icon: 'fa-solid fa-diamond-turn-right'},
    {value: 'view-menu', icon: 'fa-solid fa-utensils'},
    {value: 'find-table', icon: 'fa-solid fa-calendar-day'},
    {value: 'place-order', icon: 'fa-solid fa-cart-shopping'},
    {value: 'make-appointment', icon: 'fa-solid fa-calendar-day'},
    {value: 'message-us', icon: 'fa-solid fa-message'},
    {value: 'whatsapp-us', icon: 'fa-solid fa-message'},
    {value: 'get-quote', icon: 'fa-solid fa-receipt'},
];

const icon = computed(() => actions.find(i => i.value === props.type)?.icon);
</script>
